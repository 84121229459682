<template>
  <div class="push" style="height:32px;cursor:default;">
    <el-dropdown trigger="click" @command="profileHandleCommand" szie="medium">
      <span class="el-dropdown-link">
        <div class="profile">
          <!-- <Icon iconName="profile_avatar" /> -->
          <avatar :username="avatarUserName" :size="24" color="#26ae4d" backgroundColor="white" :src="avatar"></avatar>
          <div style="text-align:left">{{ userName }}</div>
          <div class="push" style="padding-right:8px;padding-left:0px;">
            <Icon iconName="down_triangle_white" />
          </div>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="profile"
          ><img class="svg" :src="require('../assets/icons/my_profile_green.svg')" />{{ $t("Мій профіль") + "..." }}</el-dropdown-item
        >
        <el-dropdown-item command="selectCorp" divided
          ><img class="svg disabled" :src="require('../assets/icons/settings_yellow.svg')" />{{ $t("Вибрати середовище...") }}</el-dropdown-item
        >
        <el-dropdown-item divided command="locale-ua"
          ><span :class="{ active: activeLocale == 'ua' }">{{ $t("Українська") }}</span></el-dropdown-item
        >
        <!-- <el-dropdown-item command="locale-ru"
          ><span :class="{ active: activeLocale == 'ru' }">{{ $t("Російська") }}</span></el-dropdown-item
        > -->
        <el-dropdown-item command="locale-en"
          ><span :class="{ active: activeLocale == 'en' }">{{ $t("Англійська") }}</span></el-dropdown-item
        >
        <el-dropdown-item divided command="about"
          ><img class="svg disabled" :src="require('../assets/icons/info-circle-solid.svg')" />{{ $t("Про сервіс...") }}</el-dropdown-item
        >
        <el-dropdown-item divided command="logout"
          ><img class="svg" :src="require('../assets/icons/logout.svg')" />{{ $t("Завершити сеанс") }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <EditUserProfile @commit="profileCommitChanges" />
    <SelectCorp @select-commit="onSelectCorp" />
    <About />
  </div>
</template>

<script>
//icon="el-icon-switch-button"
import Icon from "@/components/IconPack";
import { bus } from "@/main";
import { _ } from "vue-underscore";
import Avatar from "vue-avatar";

export default {
  components: {
    Icon,
    EditUserProfile: () => import("@/components/forms/profile/EditUserProfile"),
    SelectCorp: () => import("@/components/forms/SelectCorp"),
    Avatar,
    About: () => import("@/components/forms/About"),
  },
  data() {
    return {};
  },
  computed: {
    userName() {
      var profile = this.$store.getters["main/userProfile"]();
      return `${profile.first_name} (${profile.login})`;
    },
    avatarUserName() {
      var profile = this.$store.getters["main/userProfile"]();
      return `${profile.first_name} ${profile.last_name}`;
    },
    avatar() {
      var profile = this.$store.getters["main/userProfile"]();
      return profile.avatar;
    },
    activeLocale() {
      return this.$store.getters["main/locale"];
    },
  },
  methods: {
    onSelectCorp(data) {
      if (data && data.corp && !data.cancel) {
        this.$store.dispatch("main/setCurrentCorp", { corp: data.corp, router: this.$router });
      }
    },
    editProfile() {
      bus.$emit("forms.profile.edit.show", {
        title: $tt("Мій профіль"),
        operation: "update",
        initModel: this.$store.getters["main/userProfile"](),
        userSettings: this.$store.getters["main/userSettings"]("userSettings"),
      });
    },
    profileCommitChanges(data) {
      var loading = this.$loading({ target: "#EditUserProfileDialog>div", text: this.$t("Будь ласка, зачекайте...") });
      this.$store
        .dispatch("main/updateUserProfile", data.model)
        .finally(() => {
          loading && loading.close();
        })
        .then(() => {
          bus.$emit("forms.profile.edit.hide");
          this.$message.success($tt("Профіль користувача оновлено!"));
          this.$store.dispatch("main/updateUserSettings", { userSettings: data.userSettings }).then((needGlobalReload) => {
            this.$message.success($tt("Налаштування оновлено!"));
          });
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка збереження!"), err);
        });
    },
    changeLocale(locale) {
      this.$store.dispatch("main/updateLocale", locale).then(() => {
        //this.$router.go();
      });
    },
    profileHandleCommand(command) {
      var that = this;
      switch (command) {
        case "profile": {
          this.editProfile();
          break;
        }
        case "locale-en": {
          this.changeLocale("en");
          break;
        }
        case "locale-ua": {
          this.changeLocale("ua");
          break;
        }
        case "locale-ru": {
          this.changeLocale("ru");
          break;
        }
        case "selectCorp": {
          //this.$router.push("/corp");
          bus.$emit("forms.corps.select.show", {
            title: $tt("Вибір оточення"),
            initModel: { corp: this.$store.getters["main/corp"]() },
          });
          break;
        }
        case "about": {
          bus.$emit("forms.about.show", {
            title: $tt("Про сервіс..."),
          });
          break;
        }
        case "logout": {
          this.$confirm($tt("Ви дійсно бажаєте завершити сеанс?"), $tt("Увага"), {
            confirmButtonText: $tt("Так"),
            cancelButtonText: $tt("Ні"),
            type: "warning",
          })
            .then(() => {
              that.$store.dispatch("main/setGlobalLoading", $tt("Будь ласка, зачекайте..."));
              setTimeout(() => {
                that.$router.push("/login");
                that.$store.dispatch("main/setGlobalLoading", "");
              }, 300);
            })
            .catch(() => {});
          break;
        }
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
span.active {
  font-weight: 700;
}
.profile {
  height: 32px;
}
</style>
