<template>
  <img v-if="!type" :src="iconNameComp" :class="{ disabled: disabled }" @click="onClick($event)" :title="title" />
  <i v-else :class="iconClassComp" @click="onClick($event)" :title="title" :style="iconStyleComp" />
</template>

<script>
  export default {
    name: "IconPack",
    props: {
      iconName: null,
      disabled: Boolean,
      title: String,
      type: String,
      color: null,
      iconStyle: null,
    },
    data() {
      return {
        icons: {
          plus_green: require("../assets/icons/plus_green.svg"),
          edit_yellow: require("../assets/icons/edit_yellow.svg"),
          edit_small: require("../assets/icons/edit_small.svg"),
          delete_red: require("../assets/icons/delete_red.svg"),
          delete_small_red: require("../assets/icons/delete_small_red.svg"),
          import_blue: require("../assets/icons/import_blue.svg"),
          export_blue: require("../assets/icons/export_blue.svg"),
          copy_green: require("../assets/icons/copy_green.svg"),
          profile_avatar: require("../assets/icons/profile_avatar.svg"),
          more_white: require("../assets/icons/more_white.svg"),
          arrow_left_white: require("../assets/icons/arrow_left_white.svg"),
          letter_a_white: require("../assets/icons/letter_a_white.svg"),
          down_triangle_white: require("../assets/icons/down_triangle_white.svg"),
          down_triangle_grey: require("../assets/icons/down_triangle_grey.svg"),
          info: require("../assets/icons/info-circle-solid.svg"),
          tab_icon_green: require("../assets/icons/tab_icon_green.svg"),
          settings_yellow: require("../assets/icons/settings_yellow.svg"),
          settings_yellow: require("../assets/icons/attach_green.svg"),
          bonus_matrix_red: require("../assets/icons/bonus_matrix_red.svg"),
          apply_for_select_green: require("../assets/icons/apply_for_select_green.svg"),
          apply_for_select_red: require("../assets/icons/apply_for_select_red.svg"),
          reload_navy: require("../assets/icons/reload_navy.svg"),
          orgs_blue: require("../assets/icons/orgs_blue.svg"),
          select_corp_blue: require("../assets/icons/select_corp_blue.svg"),
          set_new_corp_blue: require("../assets/icons/set_new_corp_blue.svg"),
          clock_carrot: require("../assets/icons/clock_carrot.svg"),
          persons_green: require("../assets/icons/persons_green.svg"),
        },
      };
    },
    methods: {
      onClick(event) {
        if (!this.disabled) this.$emit("onClick", event);
      },
    },
    computed: {
      iconClassComp() {
        return `${this.type}${this.iconName} ${this.disabled ? "disabled" : ""}`;
      },
      iconStyleComp() {
        return Object.assign({}, this.iconStyle, { color: this.color });
      },
      iconNameComp() {
        if (typeof this.iconName == "function") return this.icons[iconName(this)];
        else return this.icons[this.iconName];
      },
    },
  };
</script>
<style scoped>
  .disabled {
    opacity: 0.3;
  }
</style>
