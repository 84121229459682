<template>
  <div class="header-button" :title="titleComp" :class="{ 'button-label': label, dropdown: dropdown, disabled: disabled }" @click="onClick($event)">
    <Icon :iconName="icon" :title="titleComp" :disabled="disabled" @onClick="onClick($event)" />
    <div v-if="label" class="label" :class="{ 'label-crop': dropdown, disabled: disabled }">
      {{ label }}
    </div>
    <Icon iconName="down_triangle_grey" v-if="dropdown" />
  </div>
</template>

<script>
import Icon from "@/components/IconPack";

export default {
  name: "ToolbarIconButton",
  props: {
    icon: String,
    label: null,
    dropdown: null,
    disabled: Boolean,
    title: String,
  },
  components: {
    Icon,
  },
  data() {
    return {};
  },
  methods: {
    onClick(event) {
      if (!this.dropdown) event.stopPropagation();
      if (this.disabled) event.stopPropagation();
      else this.$emit("onClick", this);
    },
  },
  computed: {
    titleComp() {
      if (this.disabled) return null;
      else return this.title;
    },
  },
};
</script>

<style scoped>
.header-button {
  min-width: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 32px;
  cursor: default;
}

.header-button:hover {
  transform: translate(1px, 1px);
  background: rgba(220, 230, 220, 0.5);
  transition: all, 0.2s;
}

.disabled,
.disabled:hover {
  transform: none;
  cursor: not-allowed;
  background: rgba(240, 240, 240, 0.4);
  color: gray;
}

.dropdown,
.button-label {
  width: auto;
  padding: 0 8px;
}

.dropdown > div.label,
.button-label > div.label {
  padding: 0 8px;
  font-size: 14px;
}

.dropdown > img {
  padding-left: 8px;
}

.dropdown > div.label-crop {
  padding-right: 0;
}

.dropdown > div.label {
  font-size: 14px;
}
</style>
