import Vue from "vue";
import Icon from "@/components/IconPack";

export default Vue.extend({
  template: `
  <div class="action-cell" :style="styleComp()">
      <Icon v-for="(icon,index) in this.params.colDef.cellIcons" :key="index" v-if="isShow(icon)" 
      :iconName="iconComp(icon)" 
      :title="titleComp(icon)" 
      :class="icon.iconClass" 
      :type="icon.type"
      :color="colorComp(icon)"
      :iconStyle="iconStyleComp(icon)"
      @onClick="onClick($event,icon)"/>
      <span> {{this.params.value}}</span>
  </div>`,
  data: function() {
    return {};
  },

  components: {
    Icon,
  },
  methods: {
    onClick(event, icon) {
      event.stopPropagation();
      icon && icon.onClick && icon.onClick(this.params);
    },
    iconComp(icon) {
      if (icon.icon) {
        if (typeof icon.icon == "function") return icon.icon(this.params);
        else return icon.icon;
      } else return null;
    },
    colorComp(icon) {
      if (icon.color) {
        if (typeof icon.color == "function") return icon.color(this.params);
        else return icon.color;
      } else return null;
    },
    styleComp() {
      if (this.params.colDef.cellRendererStyle) {
        if (typeof this.params.colDef.cellRendererStyle == "function") return this.params.colDef.cellRendererStyle(this.params);
        else return this.params.colDef.cellRendererStyle;
      } else return null;
    },
    iconStyleComp(icon) {
      if (icon.style) {
        if (typeof icon.style == "function") return icon.style(this.params);
        else return icon.style;
      } else return null;
    },
    titleComp(icon) {
      if (icon.title) {
        if (typeof icon.title == "function") return icon.title(this.params);
        else return icon.title;
      } else return null;
    },
    isShow(icon) {
      if (this.params.node.isRowPinned()) return false;
      if (icon.show) {
        if (typeof icon.show == "function") return icon.show(this.params);
        else return icon.show;
      } else return true;
    },
    isShowStub(icon) {
      if (!this.isShow(icon) && icon.showStub) {
        if (typeof icon.showStub == "function") return icon.icon.showStub(this.params);
        else return icon.showStub;
      } else return false;
    },
  },
});
