<template>
  <div class="bcrumbs">
    <div v-for="(item, index) in routeBreadCrumbs" :key="index">
      <div v-if="item.to">
        <router-link :to="typeof item.to == 'function' ? item.to() : item.to" :title="typeof item.title == 'function' ? item.title() : item.title">
          {{ $t(item.name) }}</router-link
        >
      </div>
      <div v-else :title="item.title">{{ $t(item.name) }}</div>
      <Icon iconName="more_white" v-if="!item.end" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/IconPack";
import { _ } from "vue-underscore";

export default {
  components: { Icon },
  props: {},
  name: "BreadCrumbs",
  data() {
    return {};
  },
  computed: {
    routeBreadCrumbs() {
      var barray = this.$route.meta.breadCrumbs.map((element, index, array) => {
        if (typeof element == "function") return _.extend(element(), { end: index == array.length - 1 });
        else return { name: element.name, end: index == array.length - 1, to: element.to };
      });
      return barray;
    },
  },
};
</script>
>
<style scoped>
.bcrumbs,
.bcrumbs > div {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.bcrumbs > div > div:hover {
  opacity: 0.7;
  transition: opacity, 0.3s;
}
.bcrumbs > div > div {
  padding: 0 4px;
}

.bcrumbs > div > a {
  padding: 0;
}
.bcrumbs > div > a {
  text-decoration: none !important;
}
.bcrumbs a {
  text-decoration: none !important;
}
a.router-link-active {
  color: white;
  text-decoration: none !important;
}
</style>
